/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Msg from 'vux/src/components/msg';
import XButton from 'vux/src/components/x-button';
export default {
  name: 'PayResult',
  components: {
    Msg: Msg,
    XButton: XButton
  },
  data: function data() {
    return {
      addMoney: '',
      sj: '',
      flag: false,
      icon: '',
      title: ''
    };
  },
  // beforeRouteLeave(to, from, next) {
  //   next({replace: true, redirect: '/Recharge'}); //目标路由 重定向
  // },
  methods: {
    backPay: function backPay() {
      var _this = this;

      this.$utils.Tools.getEnv(function (res) {
        if (res === 0) {
          _this.$wechat.miniProgram.navigateBack({
            delta: 1
          });
        } else {
          _this.$router.go(-1); // this.$router.replace('/Recharge');

        }
      });
    },
    getWxResultDetail: function getWxResultDetail() {
      var _this2 = this;

      this.$api.Home.getResultDetail({
        orderId: this.$route.query.outTradeNo,
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.icon = 'warn';
          _this2.title = '充值失败';

          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.addMoney = data.addMoney;
          _this2.sj = data.sj;
          _this2.icon = 'success';
          _this2.title = '充值成功';
          _this2.flag = true;
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    getDtResultDetail: function getDtResultDetail() {
      var _this3 = this;

      this.$api.Home.getDtResultDetail({
        orderId: this.$route.query.outTradeNo,
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        _this3.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.icon = 'warn';
          _this3.title = '充值失败';

          _this3.$utils.Tools.toastShow(msg);
        } else {
          _this3.addMoney = data.addMoney;
          _this3.sj = data.sj;
          _this3.icon = 'success';
          _this3.title = '充值成功';
          _this3.flag = true;
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {
    var _this4 = this;

    this.$utils.Tools.getEnv(function (res) {
      if (res === 0 || res === 1) {
        _this4.getWxResultDetail();
      } else {
        _this4.getDtResultDetail();
      }
    });
  }
};